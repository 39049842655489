import styles from "./Section4.module.css";
import React from "react";
import Button from "../../../UI/Button";

function Section4() {
  return (
    <section className={styles.section4}>
      <div data-aos="fade-right" className={styles.imgbx}>
        <img
          width="60%"
          className={styles.img1}
          src="https://ik.imagekit.io/0rsvz9vomoe/skin-co/test_purpose/New_Folder/small-1-size-1__1__iPC2DrW6B.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1668602950800"
          alt=""
        />
        {/* <img
          className={styles.img2}
          src="https://ik.imagekit.io/0rsvz9vomoe/skin-co/test_purpose/New_Folder/Untitled-2__1__zCmnGRNCo.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1675415199464"
          alt=""
        /> */}
      </div>

      <div
        className={styles.contentbx}
        style={{ marginTop: "100px", padding: "40px" }}
      >
        <div className={styles.formbx}>
          <h1>Book Your Consultation Today!</h1>
          <p>
            "Get in touch with Healthy Skin Co for personalized skin care
            solutions. Whether you have a question or need to book an
            appointment, our team is here to help. Reach out and take the first
            step toward healthy, glowing skin!"
          </p>
          <br />
          <button className={styles.navButton}>
            <a
              href="https://click4appointment.com/book-appointment-guest/408954/3081"
              style={{ textDecorationColor: "#5cdb95" }}
            >
              Book an Appointment
            </a>
          </button>
        </div>
      </div>
    </section>
  );
}

export default Section4;
