import React from "react";
import styles from "./AboutWelcome.module.css";

function AboutWelcome() {
  return (
    <>
      <div style={{ color: "white", backgroundColor: "black" }}></div>
      <div className={styles.mainContaier}>
        <div className={styles.contentContainer}>
          <h1>
            Welcome to <span>Healthy Skin Co</span>
          </h1>
          <p>
            Healthy Skin Co Clinic is an Aesthetic, Dermatology & Trichology
            centre in Malleshwaram, Bengaluru, headed by Chief Dermatologist Dr.
            Soumya Soragavi is an experienced Dermatologist, Trichologist and
            Cosmetologist in Bangalore. She completed her MBBS from Belgaum
            Institute of Medical Sciences, Belgaum, Bangalore, in 2012 and her
            MD (Doctor of Medicine in Dermatology) from Sri Devaraj Urs Medical
            College, Kolar, Bangalore, in 2017.
          </p>
          <p>
            She obtained FRGUHS Fellowship in Aesthetic Dermatology And
            Cosmetology from CUTIS ACADEMY OF CUTANEOUS SCIENCES affiliated to
            RGUHS in 2019. The clinic has U.S. FDA approved Lasers for Hair
            Removal, Pigmentation, Acne Scars & Facial Rejuvenation.
          </p>
        </div>
        <div className={styles.imageContaier}>
          <div className={styles.contentbx} style={{ padding: 50 }}>
            {/* <div className={styles.formbx}>
              <h1>Feel free to contact us anytime. We will get back to you soon!</h1>
              <p>Book An Appointment</p>
              <form className={styles.formContainer}>
                <div>
                  <br />
                  <input type="name" placeholder="Enter your name" />
                </div>
                <div>
                  <br />
                  <input type="name" placeholder="Enter your email" />
                </div>
                <div>
                  <br />
                  <input type="tel" placeholder="Enter your phone no" />
                </div>
                <div>
                  <br />
                  <textarea type="name" placeholder="Your message" />
                </div>
                <button type="submit" className={styles.navButton}>
                  Submit
                </button>
              </form>
            </div> */}
            <div className={styles.formbx}>
              <h1>Book Your Consultation Today!</h1>
              <p>
                "Get in touch with Healthy Skin Co for personalized skin care
                solutions. Whether you have a question or need to book an
                appointment, our team is here to help. Reach out and take the
                first step toward healthy, glowing skin!"
              </p>
              <br />
              <button
                className={styles.navButton}
                style={{
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <a
                  href="https://click4appointment.com/book-appointment-guest/408954/3081"
                  style={{ textDecorationColor: "#5cdb95" }}
                >
                  Book an Appointment
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutWelcome;
