import React from "react";
import styles from "./ContactForm.module.css";

function ContactForm() {
  return (
    <>
      {/* <span style={{color:"white"}}>Home | Contact Us</span> */}
      {/* <hr/> */}
      <div className={styles.contact}>
        <div data-aos="fade-up" className={styles.formDivMain}>
          <div className={styles.left} style={{ padding: 20 }}>
            {/* <form className={styles.formDiv}>
              <h1>Get In Touch With US</h1>
              <input type="text" placeholder="First Name" />
              <input type="text" placeholder="Last Name" /> <br />
              <input type="email" placeholder="Email Address" />
              <input type="tel" placeholder="Phone No" />
              <br />
              <textarea
                style={{ margin: "10px" }}
                rows="5"
                cols="27"
                placeholder="Drop a Message"
              ></textarea>
              <br />
              <input type="submit" value="submit" />
            </form> */}
            <div style={{ width: "100%", height: "400px" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3290.203786764529!2d77.56545799999999!3d13.003651999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDAwJzEzLjIiTiA3N8KwMzMnNTUuNyJF!5e1!3m2!1sen!2sin!4v1739511730251!5m2!1sen!2sin"
                title="Google Maps"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className={`${styles.address} ${styles.right}`}>
            <p>
              Feel free to contact us anytime. We will get back to you soon!
            </p>
            <div>
              <h4>Healthy Skin Co Clinic Adress</h4>
              <div>
                <address>
                  <span>
                    <p>
                      <a href="https://maps.app.goo.gl/cdYRGsFqb6GcCK91A">
                        5/1, 13th Cross, 8th Main Rd, Malleshwaram, Bengaluru,
                        Karnataka 560003
                      </a>
                    </p>
                  </span>
                  <span>
                    <p>
                      <a href="tel:9663408115">+91 9663408115</a>
                    </p>
                  </span>
                </address>
              </div>
            </div>
            {/* <div>
        <h4>Nagarabhavi Clinic Contact Info</h4>
        <div>
            <address>
                <span>
                    <p>#89, 17th cross, MC layout, Near Balagangadharnath Swamy Metro Station, Vijayanagar, Bangalore - 560040</p>
                </span>
            </address>
        </div>
        </div> */}

            <div>
              <style>
                {`
          .book-appointment-btn {
            background-color: #15582d;
            color: white;
            font-weight: 600;
            padding: 12px 24px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            transition: transform 0.2s, background-color 0.2s;
            border: none;
          }
          .book-appointment-btn:hover {
            background-color: #15582d;
            transform: scale(1.05);
          }
        `}
              </style>
              <a href="https://click4appointment.com/book-appointment-guest/408954/3081">
                <button className="book-appointment-btn">
                  Book Appointment
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
